import cx from 'classnames';
import styles from './Button.module.css';
import Link from 'next/link';
import { NovaFormButtonLinkProperties } from './ButtonInterfaces';
import { Arrow } from '@klokgroep/shared-components/src/Icons';
import { ButtonArrow } from './ButtonArrow';
import { useButtonArrowResizer } from './useButtonArrowResizer';
import { useRef } from 'react';

export const NovaformButtonLink = ({
  children,
  reverse = false,
  theme = 'redText',
  target,
  ...rest
}: NovaFormButtonLinkProperties) => {
  const innerReference = useRef<HTMLAnchorElement>(null);
  const svgReference = useRef<SVGSVGElement>(null);

  useButtonArrowResizer(innerReference, svgReference);

  return (
    <Link className={getClassNames(theme, reverse)} target={target} {...rest}>
      <span ref={innerReference} className={styles.inner}>
        {children}
      </span>
      {theme === 'redText' ? <Arrow /> : <ButtonArrow ref={svgReference} />}
    </Link>
  );
};

const getClassNames = (theme: NovaFormButtonLinkProperties['theme'], reverse: boolean) =>
  cx(styles.sharedStyles, styles.novaform, {
    [styles.outline]: theme === 'outline',
    [styles.outlineInverted]: theme === 'outlineInverted',
    [styles.reverse]: reverse,
    [styles.redText]: theme === 'redText',
    [styles.square]: theme === 'square',
  });
